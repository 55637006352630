/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "prismjs/themes/prism-okaidia.css"
import "normalize.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./src/assets/css/animate.min.css"
import "./src/assets/css/boxicons.min.css"
import "./src/assets/css/flaticon.css"
import "./src/assets/css/style.css"
import "./src/assets/css/responsive.css"
import "./src/assets/css/custom.scss"
import "./src/assets/css/fonts.scss"

