module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dashbot.io","short_name":"Dashbot.io","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d13b69ee6d264b496e48cf6eaea2f07d"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"2016":"/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js","2017":"/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js","2018":"/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js","2019":"/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js","2020":"/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js","tour":"/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/tour/tour.js","default":"/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js"},"gatsbyRemarkPlugins":["gatsby-remark-images"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-77566875-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"wMKEATveL6a6yGFdm3J6ytPaRB4J2Oha","trackPage":true,"trackPageDelay":50,"delayLoad":true,"delayLoadTime":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
